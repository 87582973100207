<template>
  <li class="nav-list-item-mobile">
    <SlotLink
      v-if="!children.length && url"
      :url="url"
      show-chevron
      class="nav-list-item-mobile__link"
      :class="{ 'is-level-1': activeLevel === 1 }"
    >
      <div class="nav-list-item-mobile__title">
        <span>{{ title }}</span>
      </div>
    </SlotLink>
    <button
      v-else
      type="button"
      class="nav-list-item-mobile__link"
      :class="{ 'is-level-1': activeLevel === 1 }"
      @click="flyoutStore.addMenuBranch(uid, index)"
    >
      <span>{{ title }}</span>

      <AppIcon
        class="nav-list-item-mobile__chevron"
        icon="icon-up"
        :is-raw="true"
      />
    </button>
  </li>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },
    url: {
        type: String,
        default: '',
    },
    children: {
        type: Array,
        default: () => [],
    },
    level: {
        type: Number,
        default: 0,
    },
    uid: {
        type: String,
        default: '',
    },
    index: {
        type: Number,
        default: null,
    },
});

/*
  Get the flyout store
*/
const flyoutStore = useFlyoutStore();
const activeLevel = computed(() => flyoutStore.getActiveLevel);
</script>

<style lang="scss">
.nav-list-item-mobile {
    .nav-list-item-mobile__link {
        @include typo-font('regular');
        @include typo-size('navLevel2');

        position: relative;
        display: flex;
        min-width: 100%;
        padding: 24px 0 24px 20px;
        border-top: 2px solid $C_GREEN_DARK_OPACITY_10;
        color: $C_PRIMARY;
        widows: 100%;

        @include mobile {
            @include typo-size('navLevel3');

            padding: 20px 0 20px 20px;

            &.is-level-1 {
                @include typo-size('navLevel2');

                padding: 24px 0 24px 20px;
            }
        }
    }

    &:last-of-type {
        border-bottom: 2px solid $C_GREEN_DARK_OPACITY_10;
    }

    .app-icon {
        position: absolute;
        top: 50%;
        right: 30px;
        width: 16px;
        height: 16px;
        color: currentColor;
        transform: rotate(90deg) translateX(-50%);
        transform-origin: center;

        @include mobile {
            right: 11px;
        }
    }
}
</style>
